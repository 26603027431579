import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
//import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom"

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import global_de from './translations/de/global.json'
import global_en from './translations/en/global.json'
import global_es from './translations/es/global.json'
import global_fr from './translations/fr/global.json'
import global_it from './translations/it/global.json'
import global_nl from './translations/nl/global.json'
import global_pt from './translations/pt/global.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    de: {
      global: global_de
    },
    en: {
      global: global_en
    },
    es: {
      global: global_es
    },
    fr: {
      global: global_fr
    },
    it: {
      global: global_it
    },
    nl: {
      global: global_nl
    },
    pt: {
      global: global_pt
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
