import React from 'react'
import 'antd/dist/antd'
import { Layout} from 'antd'
const {Footer} = Layout

function FooterApp(props: any) {
    return (
        <Footer style={{  textAlign: 'center', backgroundColor:props.name}}>@Top Events </Footer>
    );
}

export default FooterApp;
