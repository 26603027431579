import React from 'react'
import { Avatar, Card, Col } from 'antd'
import Meta from 'antd/es/card/Meta'

const venueStyle: React.CSSProperties = {
    width: '100%',
    cursor: 'pointer'
};

function VenuesApp(props: any) {

    let data: any[] = []

    const data_1 = [
        {
            id: 1,
            imagen: "https://media.viajando.travel/p/521ab05e9602dfc093f53bf2d2551285/adjuntos/236/imagenes/000/517/0000517700/1200x675/smart/movistar-arena-santiago.jpg",
            name: "Movistar Arena",
            country: 0
        },
        {
            id: 2,
            imagen: "https://www.teatrocaupolican.cl/wp-content/uploads/2022/12/Teatro-Caupolic-n-1024x683.jpg",
            name: "Teatro Caupolicán",
            country: 0
        },
        {
            id: 3,
            imagen: "https://i0.wp.com/www.agendamusical.cl/wp-content/uploads/2015/05/IMG_0069.jpg",
            name: "Teatro Cariola",
            country: 0
        },
        {
            id: 4,
            imagen: "https://media.bandsintown.com/300x300/9366051.webp",
            name: "Teatro Coliseo",
            country: 0
        },
        {
            id: 5,
            imagen: "https://media.bandsintown.com/300x300/14512147.webp",
            name: "Blondie",
            country: 0
        },
        {
            id: 6,
            imagen: "https://media.bandsintown.com/300x300/10180411.webp",
            name: "Teatro Nescafé de las Artes",
            country: 0
        },
        {
            id: 7,
            imagen: "",
            name: "Club Chocolate",
            country: 0
        },
        {
            id: 8,
            imagen: "",
            name: "Quinta Vergara",
            country: 0
        }

    ]

    const data_3 = [
        {
            id: 101,
            imagen: "https://www.lanacion.com.ar/resizer/Yx6iJ2V16MsO7NQBUM7qmzc5H6A=/1920x0/filters:format(webp):quality(80)/cloudfront-us-east-1.images.arcpublishing.com/lanacionar/CQVXEZPGMREKXPABQNS4GXJXDE.jpg",
            name: "Luna Park",
            country: 0
        },
        {
            id: 102,
            imagen: "",
            name: "Estadio River Plate",
            country: 0
        },
        {
            id: 103,
            imagen: "",
            name: "Teatro Colón",
            country: 0
        },
        {
            id: 104,
            imagen: "",
            name: "Estadio José Amalfitani",
            country: 0
        },
        {
            id: 105,
            imagen: "",
            name: "Movistar Arena ",
            country: 0
        },
        {
            id: 106,
            imagen: "",
            name: "Gran Rex",
            country: 0
        },

    ]

    const data_2 = [
        {
            id: 201,
            imagen: "",
            name: "Estadio San Marcos",
            country: 0
        },
        {
            id: 202,
            imagen: "",
            name: "Teatro Marsano",
            country: 0
        },
        {
            id: 203,
            imagen: "",
            name: "Teatro Municipal de Lima",
            country: 0
        },
        {
            id: 204,
            imagen: "",
            name: "Centro de Convenciones",
            country: 0
        },
        {
            id: 205,
            imagen: "",
            name: "Gran Teatro Nacional",
            country: 0
        },
        {
            id: 206,
            imagen: "",
            name: "Teatro Segura",
            country: 0
        }
    ]

    let country
    if (localStorage.getItem("country") != null) {
        country = JSON.parse(localStorage.getItem("country") || '')
        if (country.id === '1') {
            data = data_1
        } else if (country.id === '2') {
            data = data_2
        } else if (country.id === '3') {
            data = data_3
        } else {
            data = data_1
        }
    }
    else {
        country = { id: 1 }
        data = data_1
    }

    return (
        <>
            {data?.map((value: any, i: number) => {
                return (
                    <Col span={8} xs={24} sm={24} md={12} lg={6}>
                        <Card style={venueStyle} key={value.id} hoverable>
                            <Meta
                                avatar={<Avatar src={`${value.imagen}`} style={{ width: 80, height: 80 }} />}
                                title={`${value.name}`}
                                description={''}
                            />
                        </Card>
                    </Col>
                );
            })}

        </>
    );
}

export default VenuesApp;
