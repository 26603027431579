
import { initializeApp } from "firebase/app"
import { getFirestore } from "@firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCXZmqz-Zw6-oMWrdTSczK5INK5XbtSbOU",
  authDomain: "events-pro-manager.firebaseapp.com",
  projectId: "events-pro-manager",
  storageBucket: "events-pro-manager.appspot.com",
  messagingSenderId: "86704607176",
  appId: "1:86704607176:web:031b3125d2a36766fc3afd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
