import { Avatar, Col, List, Row, Typography } from 'antd'
const { Title, Text } = Typography

function EventsNews(props: any) {

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0, marginTop: 20 }} >
                <Col xs={24} sm={16} md={16}>

                    <List

                        itemLayout="vertical"
                        size="large"
                        header={<Title level={4}>Noticias destacadas</Title>}
                    >
                        {/*actions={[
                            <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                            <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                            <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />
                        ]}  */}
                        {props.showNews?.map((value: any, i: number) => {
                            return (

                                <List.Item
                                    key={`${value['title']} ===`}
                                    extra={
                                        <img
                                            className='hide-app'
                                            width={200}
                                            alt={value['title']}
                                            src={value['imagen']}
                                        />
                                    }
                                >

                                    <List.Item.Meta
                                        description={`${value['source']}`}
                                    />
                                    <a href={value['url']} target='_blank' rel="noreferrer" style={{ color: '#ffffffd9' }}>
                                        <Text strong >{`${value['title']}`}</Text>
                                    </a>

                                </List.Item>
                            );
                        })}

                    </List>
                </Col>

                <Col xs={24} sm={8} md={8}>
                    <List
                        itemLayout="horizontal"
                        header={<Title level={4}>Ranking</Title>}>

                        {props.showRanking?.map((value: any, i: number) => {
                            return (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={value['image']} size={55} shape='square' />}
                                        title={<a href={value['url']} target='_blank' rel="noreferrer">{value['name']}</a>}
                                        description={value['track_name']}
                                    />
                                </List.Item>
                            );
                        })}
                    </List>

                </Col>
            </Row>
        </>
    );
}

export default EventsNews;
