import React, { useEffect, useState } from 'react'
import './App.css'
import { Button, Card, Col, Menu, Row, Typography, theme, Tooltip, Spin, DatePicker, DatePickerProps, Drawer, ConfigProvider, Tabs, Select, Modal } from 'antd'
import { Layout, Space } from 'antd'
import { CommentOutlined, CrownOutlined, EnvironmentOutlined, EyeOutlined, FundProjectionScreenOutlined, GlobalOutlined, HeartFilled, HeartOutlined, LinkOutlined, LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined, NotificationOutlined, RocketOutlined, SettingOutlined, ShareAltOutlined, SkinOutlined, SmileOutlined, TrophyOutlined, TwitterOutlined, UserOutlined } from '@ant-design/icons'
import { infoEvents } from './servicios/infoSearch'

import FooterApp from './components/footer'
//import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'

import { Timeline } from 'react-twitter-widgets'
import ReactGA from "react-ga4"
import VenuesApp from './pages/venues'
import ArtistApp from './pages/artist'

import { useTranslation } from 'react-i18next'
import CommentsApp from './pages/commets'
import { RangePickerProps } from 'antd/es/date-picker'
import { IEvent } from './types/Event'
import EventsNews from './components/evetnsNews'

//import dayjs from 'dayjs'
//const { Countdown } = Statistic;
//const { Option } = Select

const { defaultAlgorithm, darkAlgorithm } = theme;
const { Title, Text } = Typography
const { Header, Sider, Content } = Layout
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />

var dw: string;


//G-B6QD3S2Y6M //new ga4
//ReactGA.initialize('G-B6QD3S2Y6M'); //new ga4
ReactGA.initialize('G-KEJN1DC0K5');


function App() {

	const [t, i18n] = useTranslation("global")

	const [id, setId] = useState(1);
	const [name, setName] = useState('Chile');
	const [collapsed, setCollapsed] = useState(false);
	const { token: { colorBgContainer }, } = theme.useToken();
	const [loader, setLoader] = useState(false)
	const [loaderCountry, setLoaderCountry] = useState(false)
	const [showData, setShowData] = useState<[]>()
	const [showNews, setNewsData] = useState<[]>()
	const [loadMultimedia, setLoadMultimedia] = useState(false)
	const [showRanking, setRankingData] = useState<[]>()
	const [openModal, setOpenModal] = useState(false)
	
	const [open, setOpen] = useState(false)
	const [openDetails, setOpenDetails] = useState(false)
	const [dataDetails, setDataDetails] = useState<IEvent>()
	const [isDarkMode, setIsDarkMode] = useState(true)

	useEffect(() => {

		// Obtener la URL actual en el cliente
		const currentUrl = window.location.pathname;
		const ultimoSegmento = currentUrl.split('/').filter(Boolean).pop();
		//console.log('ultimoSegmento:::: ', ultimoSegmento)
		//const currentUrl = window.location.href;
		//console.log('url actual::: ', currentUrl)
		getValueDefault()
		setLoader(true)
		getWidth()
		getIniCategory(`${ultimoSegmento}`)
		getNews()
		getRanking()
	}, []);

	const getIniCategory = async (ultimoSegmento: string) => {
		/*const category = window.localStorage.getItem("category");
		console.log('Category almacenada store:::: ', category)
		if (category == null) {
			const dat = categoryValueUrl(ultimoSegmento)
			console.log('dat::: ', dat)
			getData(1)
		} else {
			getData(Number(category))
		}*/

		const dat = categoryValueUrl(ultimoSegmento)
		//console.log('dat::: ', dat)
		getData(Number(dat))
	}

	const categoryValueUrl = (category: string) => {
		//console.log('Ejecutando categoryValue:', category)
		switch (category) {
			case 'musica':
				setLoadMultimedia(true)
				return 1;

			case 'deportes':
				setLoadMultimedia(false)
				return 2;

			case 'teatro':
				setLoadMultimedia(false)
				return 3;

			case 'humor':
				setLoadMultimedia(false)
				return 4;

			case 'ferias':
				setLoadMultimedia(false)
				return 5;

			case 'familia':
				setLoadMultimedia(false)
				return 6;

			case 'negocios':
				setLoadMultimedia(false)
				return 7;

			default:
				setLoadMultimedia(true)
				return 1;
		}

	}

	const getValueDefault = async () => {
		const countryShow = await JSON.parse(localStorage.getItem("country") || '')
		setId(countryShow.id)
		setName(countryShow.name)
		setLoaderCountry(false)
	}

	const getWidth = () => {
		var width = window.innerWidth;
		if (width <= 576) {
			dw = '100%'
		} else {
			dw = '70%'
		}
	}

	const getData = (data: number) => {
		setLoader(true)
		window.localStorage.setItem("category", `${data}`);
		infoEvents.getEvents(data).then(({ data, success }) => {
			if (success) {
				setShowData(data)
				setTimeout(() => {
					setLoader(false)
				}, 500);
			}
		})
	}

	const getNews = () => {
		infoEvents.getNews().then(({ data, success }) => {
			if (success) {
				setNewsData(data)
			}
		})
	}

	const getRanking = () => {
		infoEvents.getRanking().then(({ data, success }) => {
			if (success) {
				setRankingData(data)
			}
		})
	}


	//////////////////////
	/*const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		message.info('Click on left button.');
		//console.log('click left button', e);
	};*/

	/*const handleMenuClick: MenuProps['onClick'] = (e) => {
		message.info(`${e.key}`);
		//console.log('click', e);
	};*/

	/*const items: MenuProps['items'] = [
		{
			label: 'Chile',
			key: '1',
			icon: <RightOutlined />,
		},
		{
			label: 'Argentina',
			key: '3',
			icon: <RightOutlined />,
		},
		/*{
			label: 'Brasil',
			key: 'Brasil',
			icon: <RightOutlined />,
		}
		{
			label: 'Mexico',
			key: 'Mexico',
			icon: <RightOutlined />,
		},
		{
			label: 'Colombia',
			key: 'Colombia',
			icon: <RightOutlined />,
		},*/
	/*{
		label: 'Peru',
		key: '2',
		icon: <RightOutlined />,
	},
];*/

	/*const menuProps = {
		items,
		onClick: handleMenuClick,
	};*/
	//////////////////////

	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		return current && current.valueOf() < Date.now();
		//return current && current < dayjs().endOf('day');
	};

	const onChange: DatePickerProps['onChange'] = (date, dateString) => {
		//console.log(date, dateString);
		//console.log(dateString);
		const data = window.localStorage.getItem("category")
		infoEvents.getEvents(Number(data), dateString).then(({ data, success }) => {
			if (success) {
				setShowData(data)
				setTimeout(() => {
					setLoader(false)
				}, 500);
			}
		})
	};

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	//const [size, setSize] = useState<SizeType>('small');
	/*const onChangeTab = (e: RadioChangeEvent) => {
		setSize(e.target.value);
	};*/


	const handleChange = (value: { value: string; label: React.ReactNode }) => {
		setId(parseInt(value.value))
		setName(`${value.label}`)
		/*console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
		console.log(value.value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
		console.log(value.label);*/
		window.localStorage.setItem("country", JSON.stringify({ id: value.value, name: value.label }));
		const category = localStorage.getItem("category")
		getData(Number(category))
		//window.localStorage.setItem("country", JSON.stringify(value.label));
		//window.localStorage.setItem("idCountry",  JSON.stringify(value.value));
	};

	const navigate = useNavigate()
	const handleClick = () => {
		setIsDarkMode((previousValue) => !previousValue);
	};

	return (


		<ConfigProvider

			theme={{
				algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
				/*token: {
					//colorPrimary: '#1D84B5',
					//colorBgBase: "#c9d1dc",
					//colorBgContainer: "#2053a0"
					//colorBgBase: '#00b96b',
				},*/
			}}
		>

			<Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
				<Layout>

					<Layout>
						<Header style={{ padding: 0, background: isDarkMode ? "#141414" : colorBgContainer, borderBottom: '1px solid', borderColor: isDarkMode ? 'rgba(253, 253, 253, 0.12)' : 'rgba(5, 5, 5, 0.06)' }} >
							<Button
								type="text"
								icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
								onClick={() => setCollapsed(!collapsed)}
								style={{
									float: 'left',
									fontSize: '16px',
									width: 64,
									height: 64,
								}}
							/>

							<DatePicker className='hide-app' disabledDate={disabledDate} onChange={onChange} placeholder={`${t("header.date")}`} bordered={false} />

							<Button
								type="text"
								icon={<HeartFilled />}
								onClick={showDrawer}
								className='btnHeaderStyle'

							/>

							<Button
								type="text"
								icon={isDarkMode ? "🔆" : "🌛"}
								onClick={handleClick}
								className='btnHeaderStyle'
							/>

							<Space style={{
								float: 'right',
								fontSize: '16px',
								width: 120,
								height: 64,
								marginRight: 10
							}} >

								<Space>
									<Spin tip="Loading..." spinning={loaderCountry}>

										<Select
											labelInValue
											//defaultValue={{ value: `${id}`, label: `${name}` }}
											value={{ value: `${id}`, label: `${name}` }}
											style={{ width: 115, marginRight: 50 }}
											onChange={handleChange}
											size="large"
											options={[
												{
													value: '1',
													label: 'Chile',
												},
												{
													value: '3',
													label: 'Argentina',
												},
												{
													value: '2',
													label: 'Peru',
												},
											]}
										/>

										{/*<Select
											labelInValue
											//defaultValue={{ value: `${id}`, label: `${name}` }}
											style={{ width: 115, marginRight: 50 }}
											value={{ value: `${id}`, label: `${name}` }}
											onChange={handleChange}
											size="large"

										>
											<Option value="1">
												Chile
											</Option>
											<Option value="3">Argentina</Option>
											<Option value="2">Peru</Option>
									</Select>*/}

									</Spin>
								</Space>
							</Space>

						</Header>
						<Layout>
							<Sider theme="light" trigger={null} collapsible collapsed={collapsed} style={{ padding: 0 }} >

								<Menu
									style={{ padding: 0, marginTop: 18, fontWeight: 'bold' }}
									theme="light"
									onClick={(value) => {
										switch (value.key) {
											case 'eventos/musica':
												navigate('eventos/musica')
												setLoadMultimedia(true)
												getData(1)
												break;
											case 'eventos/deportes':
												navigate('eventos/deportes')
												setLoadMultimedia(false)
												getData(2)
												break;
											case 'eventos/teatro':
												navigate('eventos/teatro')
												setLoadMultimedia(false)
												getData(3)
												break;
											case 'eventos/humor':
												navigate('eventos/humor')
												setLoadMultimedia(false)
												getData(4)
												break;
											case 'eventos/ferias':
												navigate('eventos/ferias')
												setLoadMultimedia(false)
												getData(5)
												break;
											case 'eventos/familia':
												navigate('eventos/familia')
												setLoadMultimedia(false)
												getData(6)
												break;
											case 'eventos/negocios':
												navigate('eventos/negocios')
												setLoadMultimedia(false)
												getData(7)
												break;
											case '/idioma':
												setOpenModal(true)
												break;
											default:
												navigate(value.key)
										}

									}}
									//defaultSelectedKeys={['1']}
									defaultSelectedKeys={[window.location.pathname]}
									items={[
										{
											key: 'eventos/musica',
											label: `${t("sidebar.music")}`,
											icon: <GlobalOutlined />,
										},
										{
											key: 'eventos/deportes',
											label: `${t("sidebar.sports")}`,
											icon: <TrophyOutlined />
										},
										{
											key: 'eventos/teatro',
											label: `${t("sidebar.theater")}`,
											icon: <CrownOutlined />
										},
										{
											key: 'eventos/humor',
											label: `${t("sidebar.humor")}`,
											icon: <SmileOutlined />
										},
										{
											key: 'eventos/ferias',
											label: `${t("sidebar.fairs")}`,
											icon: <RocketOutlined />
										},
										{
											key: 'eventos/familia',
											label: `${t("sidebar.family")}`,
											icon: <SkinOutlined />
										},
										{
											key: 'eventos/negocios',
											label: `${t("sidebar.business")}`,
											icon: <FundProjectionScreenOutlined />
										},
										{ type: 'divider' },
										{ type: 'group' },
										{
											key: '/venues',
											label: `${t("sidebar.venues")}`,
											icon: <EnvironmentOutlined />
										},
										{
											key: '/artistas',
											label: `${t("sidebar.artists")}`,
											icon: <UserOutlined />
										},
										{
											key: '/productoras',
											label: `${t("sidebar.producer")}`,
											icon: <NotificationOutlined />
										},
										{ type: 'divider' },
										{ type: 'group' },
										{
											key: '/comentarios',
											label: `${t("sidebar.comments")}`,
											icon: <CommentOutlined />
										},
										{
											key: '/idioma',
											label: `${t("sidebar.language")}`,
											icon: <SettingOutlined />
										},

									]}
								/>
							</Sider>
							{/*<Content style={contentStyle}>*/}
							<Content className='contentStyle'>
								<Routes>
									<Route path="/" element={<Navigate to="/eventos/musica" replace={true} />}></Route>
									<Route path="eventos/:id" element={<Spin spinning={loader} indicator={antIcon}>
										<Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }} >
											<DatePicker onChange={onChange} disabledDate={disabledDate} placeholder={`${t("header.date")}`} style={{ width: '100%', margin: 10 }} className='hide' bordered={true} />
											{showData?.map((value: any, i: number) => {
												return (
													<Col key={i} xs={24} sm={12} md={6}> {/* span={8} */}

														<Card
															bodyStyle={{ padding: 10 }}
															hoverable
															onClick={() => {
																setDataDetails(value)
																setOpenDetails(true)
																ReactGA.event({ category: `${value.id_category}`, action: "Detalle del Evento", label: `${value.name}`, value: value.id, });

															}}
															style={{ width: '100%', height: 370 }}>
															<Row>

																<Col span={24}>

																	<img src={value['imagen']} width={'100%'} height={200} style={{ objectFit: 'cover' }} alt='Evento' />

																	<div style={{ height: 50 }}>
																		<span style={{ lineHeight: 0.1, fontSize: 14, fontWeight: 'bold' }}>{value['name']}</span>
																	</div>
																	<div style={{ height: 50 }}>
																		<Text>{value['label']}</Text><br></br>
																		<Text>{value['label_date']}</Text>
																	</div>


																	<Row gutter={16} style={{ paddingLeft: 5, paddingTop: 5 }}>
																		<Tooltip title={`${t("page.detail")}`} >
																			<Button shape="circle" style={{ margin: 5 }} icon={<EyeOutlined />} onClick={() => {
																				setDataDetails(value)
																				setOpenDetails(true)
																				ReactGA.event({ category: `${value.id_category}`, action: "Detalle del Evento", label: `${value.name}`, value: value.id, });
																			}} />
																		</Tooltip>
																		<Tooltip title={`${t("page.save")}`}>
																			<Button shape="circle" style={{ margin: 5 }} icon={<HeartOutlined />} onClick={() => { }} />
																		</Tooltip>
																	</Row>
																</Col>
															</Row>
														</Card>
													</Col>
												);
											})}
										</Row>

										{ loadMultimedia && 
											<EventsNews  showNews={showNews} showRanking={showRanking}/>
										}

									</Spin>}>

									</Route>
									<Route path="/venues" element={
										<Row gutter={[16, 16]} style={{ margin: 10 }} >
											<VenuesApp />
										</Row>}>
									</Route>
									<Route path="/artistas" element={
										<Row gutter={[16, 16]} style={{ margin: 10 }} >
											<ArtistApp />
										</Row>}>
									</Route>
									<Route path="/productoras" element={<Row gutter={[16, 16]} style={{ margin: 10 }} ><Title level={4}> .</Title></Row>}></Route>
									<Route path="/comentarios" element={
										<Row gutter={[16, 16]} style={{ margin: 10 }} >
											<CommentsApp />
										</Row>}>
									</Route>
								</Routes>

							</Content>
						</Layout>

						<FooterApp name={isDarkMode ? "#141414" : colorBgContainer} />
					</Layout>

					<Drawer title={`${t("header.like")}`} placement="right" onClose={onClose} open={open}></Drawer>


					<Drawer title={dataDetails?.name} size={'large'} width={dw} placement="right" onClose={() => { setOpenDetails(false) }} open={openDetails}>
						{openDetails &&
							<>
								<img src={dataDetails?.imagen} width={'100%'} height={350} style={{ objectFit: 'cover', objectPosition: 'top' }} alt='Evento' />
								<Row gutter={16} style={{ paddingRight: 10, marginTop: -45, float: 'right' }}>
									{/*<span style={{color:'red'}}> Faltan: <Countdown title="Countdown" value={'2023-05-16'} format="HH:mm:ss" /> </span> */}
									<Tooltip title="Web" defaultOpen={true} >
										<Button shape="circle" style={{ margin: 5 }} icon={<LinkOutlined />} onClick={() => {
											window.open(dataDetails?.url, '_blank');
										}} />
									</Tooltip>
									<Tooltip title="Compartir" >
										<Button shape="circle" style={{ margin: 5 }} icon={<ShareAltOutlined />} onClick={() => { }} />
									</Tooltip>
									<Tooltip title="Me Gusta" >
										<Button shape="circle" style={{ margin: 5 }} icon={<HeartOutlined />} onClick={() => { }} />
									</Tooltip>
								</Row>
								<Tabs defaultActiveKey='tab1' centered>
									<Tabs.TabPane tab={`${t("page.information")}`} key="tab1">
										<Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>

											{(() => {
												if (dataDetails?.list == null || dataDetails?.tw == "") {
													return (
														<Col xs={24} sm={24} md={24} lg={24} >
															<div dangerouslySetInnerHTML={{ __html: dataDetails?.description || '' }} />
														</Col>
													)
												} else {
													return (
														<>
															<Col xs={24} sm={15} md={15} lg={15} >
																<div dangerouslySetInnerHTML={{ __html: dataDetails?.description || '' }} />
															</Col>
															<Col xs={24} sm={9} md={9} lg={9} >
																<div>
																	<iframe src={dataDetails?.list} width="100%" height="752" frameBorder={0} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='Musica'></iframe>
																</div>
															</Col>
														</>
													)
												}

											})()}
										</Row>
									</Tabs.TabPane>
									<Tabs.TabPane tab="Venue" key="tab2">
										<div>
											{dataDetails?.venue ? (
												<iframe
													title="Venue" style={{ border: 0 }} height="450" width="100%" loading="lazy"
													src={`https://maps.google.com/maps?z=16&t=m&output=embed&q=loc:${dataDetails?.venue.lat},${dataDetails?.venue.lng}`}>
												</iframe>
											) : (
												<></>
											)}
										</div>
									</Tabs.TabPane>

									{(() => {
										if (dataDetails?.tw == null || dataDetails?.tw == "") { return (<></>) }
										else {
											return (
												<Tabs.TabPane tab={<span> <TwitterOutlined /> Ultimos tweets</span>} key="tab3">
													<Timeline
														dataSource={{ sourceType: 'profile', screenName: dataDetails?.tw }}
														options={{ theme: 'dark', height: '600' }}
													/>
												</Tabs.TabPane>
											)
										}
									})()}


								</Tabs>
							</>
						}
					</Drawer>

					<Modal
						title={`${t("sidebar.language")}`}
						style={{ top: 100 }}
						open={openModal}
						onOk={() => setOpenModal(false)}
						onCancel={() => setOpenModal(false)}
						width={500}
					>
						<div style={{ margin: 10 }}>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("en") }}>English</Button>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("es") }}>Español</Button>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("de") }}>Deutsch</Button>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("it") }}>Italiano</Button>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("pt") }}>Portugues</Button>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("fr") }}>Français</Button>
							<Button shape="round" style={{ margin: 10 }} onClick={() => { i18n.changeLanguage("nl") }}>Nederlands</Button>
						</div>
					</Modal>

				</Layout>
			</Space>
		</ConfigProvider>
	);
}

export default App;
