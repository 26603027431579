import React from 'react';
import { Avatar, Typography, Row, Col } from 'antd'
const {  Text } = Typography;

const artistStyle: React.CSSProperties = {
    width: 100,
    height: 105,
    margin: 10
};

function ArtistApp(props: any) {

    const data = [
        {
            id: 4,
            name: "Rubén Blades",
            imagen: "https://media.bandsintown.com/110x110/8520852.webp"
        },
        {
            id: 5,
            name: "Bruno Mars",
            imagen: "https://img.asmedia.epimg.net/resizer/KGXdQksyYAzADEBMOuZ2mzaH21k=/1472x1104/cloudfront-eu-central-1.images.arcpublishing.com/diarioas/3ZEY57UJC5E3DNNOIIYGMI52JA.jpg"
        },
        {
            id: 6,
            name: "Jorge Drexler",
            imagen: "https://www.latercera.com/resizer/y4lZwzi1kqtsFHVbE0-EDf8iEoI=/900x600/smart/cloudfront-us-east-1.images.arcpublishing.com/copesa/3ZPUERX4RBEVNNLY3LDRK42GXI.jpg"
        },
        {
            id: 7,
            name: "Cristian Castro",
            imagen: "https://images.hola.com/imagenes/actualidad/20171006100253/cristian-castro-habla-divorcio/0-493-980/cristian-castro-getty-t.jpg"
        },
        {
            id: 8,
            name: "The Weeknd",
            imagen: "https://cdn.milenio.com/uploads/media/2023/03/21/the-weeknd-especial.jpg"
        },
        {
            id: 9,
            name: "Rihanna",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/rihana-81931723238282828.jpeg"
        },
        {
            id: 10,
            name: "Coldplay",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/coldplay-22342342341.jpeg"
        },
        {
            id: 11,
            name: "Metallica",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/metallica-234234234273492734.jpeg"
        },
        {
            id: 22,
            name: "Iron Maiden",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/iron-maiden-123123234345.jpeg"
        },
        {
            id: 12,
            name: "Taylor Swift",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/taylor-swift-91287391273912783123.jpeg"
        },
        {
            id: 13,
            name: "Miley Cyrus",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/miley-cyrus-29349273437434234.jpeg"
        },
        {
            id: 14,
            name: "Shakira",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/shakira-2342973492734.jpeg"
        },
        {
            id: 15,
            name: "Alicia Keys",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/alicia-keys-2938472934792374927834.jpeg"
        },
        {
            id: 16,
            name: "Tiesto",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/tiesto-123123123213.jpeg"
        },
        {
            id: 17,
            name: "John Mayer",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/john-mayer-2342342345678.jpeg"
        },
        {
            id: 18,
            name: "Adele",
            imagen: "https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/adele-1232342344.jpeg"
        },
        {
            id:19,
            name:"Calvin Harris",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/calvin-harris-2342342345678.jpeg"
        },
        {
            id:20,
            name:"David Guetta",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/david-guetta-392340234.jpeg"
        },
        {
            id:21,
            name:"Luis Miguel",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/luis-miguel-23423423434.jpeg"
        },
        {
            id:22,
            name:"Bad Bunny",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/bad-bunny-2034503845345.jpeg"
        },
        {
            id:24,
            name:"El Alfa",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/el-alfa-123123123938434.jpeg"
        },
        {
            id:23,
            name:"Imagine Dragons",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/imagine-dragons-23234567.jpeg"
        },
        {
            id:25,
            name:"Rammstein",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/rammstein-23423434.jpeg"
        },
        {
            id:26,
            name:"Robbie Williams",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/robbie-williams-123123232.jpeg"
        },
        {
            id:27,
            name:"KaRoL G",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/karol-g.png"
        },
        {
            id:28,
            name:"Red Hot Chili Peppers",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/red-hot.webp"
        },
        {
            id:28,
            name:"Lasso",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/lasso-123234343.jpeg"
        },
        {
            id:28,
            name:"Olga Tañón oficial",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/olga-tanon-oficial-12323434.jpeg"
        },
        {
            id:29,
            name:"Armin van Buuren",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/armin-van-buuren-233423412.jpeg"
        },
        {
            id:30,
            name:"Maroon 5",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/maroon-5-234234234.jpeg"
        },
        {
            id:31,
            name:"Green Day",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/green-day-234p23u423.jpeg"
        },
        {
            id:32,
            name:"Aerosmith",
            imagen:"https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/artist/aerosmith-323423443234.jpeg"
        }
    
    ]
    return (

        <Row>
            {data?.map((value: any, i: number) => {
                return (
                    <Col span={8} xs={24} sm={12} md={6} lg={3} style={{lineHeight:0, cursor:'pointer', textAlign:'center',}} key={value.id}>
                        <Avatar src={value.imagen} style={artistStyle} /><br/>
                        <Text strong >{`${value.name}`}</Text>
                    </Col>
                );
            })}

        </Row>
    );
}

export default ArtistApp;
