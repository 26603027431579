//import React, { useState } from 'react'
import { Button, Form, Input, Row, Space, notification } from 'antd' /*Typography*/
import { useTranslation } from 'react-i18next'
import { firestore } from '../firebase'
import { addDoc, collection } from "firebase/firestore"


type NotificationType = 'success' | 'info' | 'warning' | 'error';

function CommentsApp(props: any) {

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message,
            description,
        });
    };

    const [t] = useTranslation("global")
    const [form] = Form.useForm()

    const handleSubmit = (e: any) => {
        const ref = collection(firestore, "coments")
        let data = { testData: e }
        try {
            addDoc(ref, data)
            openNotificationWithIcon('success', '', `${t("form.success")}`)
        } catch (err) {
            openNotificationWithIcon('warning', '', `${t("form.error")}`)
            console.log(err)
        }
    }



    return (
        <Row style={{ width: '100%' }}>
            {contextHolder}
            <Form form={form} onFinish={handleSubmit} name="validateOnly" layout="vertical" autoComplete="off" style={{ width: '100%' }}>
                <Form.Item name="name" label={`${t("form.name")}`} rules={[{ required: true, message: `${t("form.pleaseName")}` }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="mail" label={`${t("form.email")}`} rules={[{ type: 'email', required: true, message: `${t("form.pleaseMail")}` }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="coment" label={`${t("form.comment")}`} rules={[{ required: true, message: `${t("form.pleaseComment")}`, min: 10 }]}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit" >{`${t("form.send")}`}</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Row>
    );
}

export default CommentsApp;

