import axios from "axios"

export class IBackendResponse<T> {
    data: T | undefined;
    success: boolean = false;
}

export const infoEvents = {
    getEvents: async(dataPoint:number,date:string = '')=> {
        //console.log('datapoin:::: ', dataPoint)
        //console.log('fecha:', date)
        const axiosInstance = axios.create({})

        let res: IBackendResponse<[]> = new IBackendResponse()
        let country
        //console.log(localStorage.getItem("country") )
        if(localStorage.getItem("country") !=null){
             country = JSON.parse(localStorage.getItem("country") || '')
        }
        else{
             country = {id:1}
        }
        //console.log('=$=$=$=$=$=id contry=====')
       // console.log(typeof country)
        //console.log(country.id)
        /*if(idCountry != undefined){
            console.log(parseInt("12"))
        }*/
      
        //console.log('=$=$=$=$=$=id contry=====')
     
        try{
            //const { data, status } = await axiosInstance.get<[]>(`${process.env.REACT_APP_BASE_URL || ""}/eventos/public?category=${dataPoint}&country=${country.id}`,{ })
            /*if(date!=''){
                const { data } = await axiosInstance.get<[]>(`${process.env.REACT_APP_BASE_URL || ""}/eventos/public?category=${dataPoint}&country=${country.id}`,{ })
            }else{
               
            }*/
            const { data } = await axiosInstance.get<[]>(`${process.env.REACT_APP_BASE_URL || ""}/eventos/public?category=${dataPoint}&country=${country.id}&date=${date}`,{ })
            //if (status === 200) {
                /*console.log("======Ejecutando infoMap==========")
                console.log(data)
                console.log("======Ejecutando infoMap==========")*/
                res.data = data
                res.success = true
            //}
        }  catch (e) {
            res.data = []
            res.success = true
            //console.log(e)
        }
        return res
    },

    getNews: async()=> {
        console.log('Ejecutando getNews')
        const axiosInstance = axios.create({})

        let res: IBackendResponse<[]> = new IBackendResponse()
       
     
        try{
          
            const { data } = await axiosInstance.get<[]>(`${process.env.REACT_APP_BASE_URL || ""}/news/public`,{ })
            //if (status === 200) {
                console.log("======Ejecutando news==========")
                console.log(data)
                console.log("======Ejecutand  news==========")
                res.data = data
                res.success = true
            //}
        }  catch (e) {
            res.data = []
            res.success = true
            //console.log(e)
        }
        return res
    },

    getRanking: async()=> {
        console.log('getRanking getNews')
        const axiosInstance = axios.create({})
        let res: IBackendResponse<[]> = new IBackendResponse()
        try{
            const { data } = await axiosInstance.get<[]>(`https://spacetodoinfo14.ams3.digitaloceanspaces.com/top-events/ranking/ranking.json`,{ })
            //if (status === 200) {

            console.log(data)
                res.data = data
                res.success = true
            //}
        }  catch (e) {
            res.data = []
            res.success = true
        }
        return res
    },

    postEvents: async (name:string,imagen:string,url:string) => {
        const axiosInstance = axios.create({})
        let res: IBackendResponse<[]> = new IBackendResponse()

        var dataSend = {
            'name': name,
            'imagen': imagen,
            'url': url,
            'status': 'no publish',
        }

        try {
            const { data } = await axiosInstance.post<[]>(`${process.env.REACT_APP_BASE_URL || ""}/eventos`, dataSend, {
               
            })
            //console.log("======Ejecutando postEvents==========")
            //console.log(data)
            //console.log("======Ejecutando postEvents==========")
            res.data = data
            res.success = true
        } catch (error) {
            res.data = []
            res.success = true
        }
        return res
    }
}